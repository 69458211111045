import React from 'react';
import { auth } from './firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Card } from 'antd';

export default function Auth() {
  const navigate = useNavigate();

  const register = async (values) => {
    const { email, password } = values;

    try {
      const authUser = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User created:', authUser);
      // Nach erfolgreicher Registrierung kannst du zum gewünschten Pfad weiterleiten
      // Hier kannst du auch Erfolgsmeldungen hinzufügen
    } catch (error) {
      alert(error.message);
    }
  };

  const signIn = async (values) => {
    const { email, password } = values;

    try {
      const authUser = await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in:', authUser);
      navigate('/retool'); // Weiterleitung zur Retool-Komponente nach erfolgreicher Anmeldung
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Card style={{ width: 300, margin: 'auto', marginTop: 50 }}>
      <Form onFinish={signIn}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Bitte gib deine E-Mail ein!' }]}
        >
          <Input placeholder="E-Mail" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Bitte gib dein Passwort ein!' }]}
        >
          <Input.Password placeholder="Passwort" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Einloggen
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}