// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBzYGEdwcDPW0fW1NYXmz7S854tyoeYcQQ",
    authDomain: "rocketleads-dd678.firebaseapp.com",
    projectId: "rocketleads-dd678",
    storageBucket: "rocketleads-dd678.appspot.com",
    messagingSenderId: "903733794009",
    appId: "1:903733794009:web:bcd0ec6261a93b4d3adac8"
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  
  export { auth };