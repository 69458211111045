import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig'; // Pfad gegebenenfalls anpassen
import { signOut } from 'firebase/auth';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    signOut(auth)
      .then(() => {
        // Weiterleitung zur Startseite
        navigate('/');
      })
      .catch((error) => {
        // Fehlerbehandlung
        console.error('Logout error', error);
      });
  }, [navigate]);

  // Zeigen ggf. ein Ladesymbol oder eine Bestätigungsmeldung an, während der Logout-Vorgang abgeschlossen wird.
  return (
    <div>Abmelden...</div>
  );
};

export default Logout;