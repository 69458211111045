import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './Auth'; // oder der Pfad zu deiner Authentifizierungskomponente
import RetoolApp from './RetoolApp'; // oder der Pfad zu deiner Retool-Ansicht
import Logout from './Logout'; // oder der Pfad zu deiner Logout-Komponente

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/retool" element={<RetoolApp />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </Router>
  );
}

export default App;