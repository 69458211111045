import React, { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';  // Stelle sicher, dass der Pfad zur firebaseConfig.js korrekt ist
import { onAuthStateChanged, getIdToken } from 'firebase/auth';

export default function RetoolApp() {
  const [retoolEmbedUrl, setRetoolEmbedUrl] = useState('');

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdToken(user)
          .then((token) => fetchEmbedUrl(token))
          .catch((error) => {
            console.error('Error while fetching ID token', error);
          });
      }
    });
  }, []);

  const fetchEmbedUrl = (token) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userJwt: token })
    };

    fetch('https://auth.rocketleads.io/embedUrl', requestOptions)
      .then(response => response.json())
      .then(data => {
        setRetoolEmbedUrl(data.embedUrl.embedUrl);
      })
      .catch(error => {
        console.error('Error fetching embed URL:', error);
      });
  };

  return (
    <div style={{ height: '100vh' }}>
      {retoolEmbedUrl && <iframe src={retoolEmbedUrl} width="100%" height="100%" title="Retool" />}
    </div>
  );
}